import { Typography } from "@material-ui/core"
import { FunctionComponent } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { Index } from "./app/pages/Index"
export const Routing: FunctionComponent = (props) => {
  return (
    <>
      <Switch>
        <Index>
          <BrowserRouter>
            <Switch>
              <Route path="*">
                <Typography variant="h5">This is the fsd-poc package.</Typography>
              </Route>
            </Switch>
          </BrowserRouter>
        </Index>
      </Switch>
    </>
  )
}
