import { HashRouter as Router } from "react-router-dom"
import { Routing } from "./Routing"
import { ThemeProvider } from "@material-ui/styles"
import theme from "./app/styles/theme"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import "@infarm/design-system/dist/design-system/design-system.css"
import "@infarm/design-system/dist/design-system/typography.css"

export interface AppProps {
  classes: Object | any
}

const styles = {
  app: {
    backgroundColor: "white",
  },
}

const App = (props: AppProps) => {
  const { classes } = props as any
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className={classes.app}>
          <Routing />
        </div>
      </Router>
    </ThemeProvider>
  )
}

App.propTypes = {
  classes: PropTypes.shape({}).isRequired,
}
export default withStyles(styles)(App)
