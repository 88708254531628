import { FunctionComponent } from 'react';
import { Container } from '@material-ui/core';
import { Loading } from '../components/Loading';

export const Index: FunctionComponent = props => {
    return (
        <>
            <Container>
                <Loading loading={false} error={''}>
                    {props.children}
                </Loading>
            </Container>
        </>
    );
};
