import {
    CircularProgress, makeStyles
} from '@material-ui/core';
import { FunctionComponent } from 'react';

const useStyles = makeStyles(theme => ({
    centered: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
}));

export const Loading: FunctionComponent<{
    loading: boolean;
    error?: string;
    inline?:boolean;
}> = ({ loading, error, inline, children }) => {
    const classes = useStyles();
    const className = !inline ? classes.centered : '';
    return (
        <>
            {loading ? (
              <div className={className}>
                  <CircularProgress />
              </div>
            ) : error ? (
                <div className={className}>
                    {error}
                </div>
            ) : (
                <>{children}</>
            )}
        </>
    );
};
