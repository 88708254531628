import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        fontSize: 16,
        h1: {
            fontSize: '3rem',
            fontWeight: 600
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 600
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: 500
        },
        button: {
            textTransform: 'none'
        }
    },
    palette: {
        primary: {
            dark: '#000',
            main: '#333',
            light: '#666'
        },
        error: {
            dark: '#E00028',
            main: '#E63353',
            light: '#EC667E'
        },
        success: {
            dark: '#6DD400',
            main: '#8ADD33',
            light: '#A7E566'
        }
    }
});

export default theme;
