import React from 'react';
import ReactDOM from 'react-dom';
import rootComponent from './App.tsx';
import singleSpaReact from 'single-spa-react';

const reactLifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent,
    errorBoundary(err, info, props) {
        console.log(err);
        return (
            <div>
                <div>This renders when a catastrophic error occurs</div>
                <code>err</code>
            </div>
        );
    }
});

export const bootstrap = reactLifecycles.bootstrap;
export const mount = reactLifecycles.mount;
export const unmount = reactLifecycles.unmount;
